import {
  blue,
  green,
  indigo,
  sky,
  slate,
  tomato,
  violet,
} from '@radix-ui/colors'
import { createStitches } from '@stitches/react'
import { Gilroy, SofiaPro, SourceSansPro } from './fonts'

export const {
  styled,
  css,
  globalCss,
  keyframes,
  getCssText,
  theme,
  createTheme,
  config,
} = createStitches({
  theme: {
    colors: {
      ...slate,
      ...tomato,
      ...violet,
      ...indigo,
      ...blue,
      ...green,
      ...sky,
    },
    fonts: {
      brand: SofiaPro.variable,
      sans: Gilroy.variable,
      text: SourceSansPro.variable,
    },
    fontSizes: {
      0: '11px',
      1: '12px',
      2: '13px',
      3: '15px',
      4: '17px',
      5: '19px',
      6: '21px',
      7: '27px',
      8: '35px',
      9: '59px',
    },
    space: {
      '-1': '0px',
      1: '4px',
      2: '8px',
      3: '16px',
      4: '32px',
      5: '48px',
      6: '64px',
      '1in': '72px',
    },
  },
  media: {
    bp1: '(min-width: 480px)',
    print: 'print',
  },
  prefix: 'st',
  utils: {
    mt: (value: string | number) => ({ marginTop: value }),
    mr: (value: string | number) => ({ marginBottom: value }),
    mb: (value: string | number) => ({ marginBottom: value }),
    ml: (value: string | number) => ({ marginBottom: value }),
  },
})
