'use client'

import { Heading } from '@/components/Heading'
import { Header } from '@/components/Home'

export function MainHeader() {
  return (
    <Header css={{ textAlign: 'center' }}>
      <Heading h1>Purposity</Heading>
      <Heading h2 css={{ fontWeight: 200 }}>
        Tax Receipt Generator
      </Heading>
    </Header>
  )
}
