import localFont from 'next/font/local'

const gilroy = localFont({
  src: [
    {
      path: './Gilroy-Regular.woff2',
      weight: '400',
      style: 'normal',
    },
    {
      path: './Gilroy-Medium.woff2',
      weight: '500',
      style: 'normal',
    },
    {
      path: './Gilroy-Bold.woff2',
      weight: '700',
      style: 'normal',
    },
  ],
  variable: '--font-gilroy',
})

export default gilroy
