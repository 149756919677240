'use client'

import { Gilroy } from '@/styles/fonts'
import { styled } from '@/styles/stitches.config'

export const Fieldset = styled('div', {
  border: 'none',
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'row',
  marginBottom: '1rem',
})

export const Page = styled('div', {
  width: '100%',
  maxWidth: 800,
  margin: '0 auto',
  padding: '0.75rem',
})

export const Header = styled('header', {
  marginTop: '5rem',
  marginBottom: '2.5rem',
})
export const Content = styled('section', {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
})

export const Footer = styled('footer', {})

export const Button = styled('button', {
  ...Gilroy.style,
  cursor: 'pointer',
  backgroundColor: '$blue4',
  color: '$blue11',
  fontWeight: 'bold',
  borderRadius: '9999px',
  fontSize: 15,
  border: '0',
  paddingBlock: 16,
  paddingInline: 32,
  '&:hover': {
    backgroundColor: '$blue5',
  },
})

export const Form = styled('form', {
  width: '100%',
  maxWidth: 400,
})

export const Input = styled('input', {
  all: 'unset',
  width: '100%',
  paddingInline: 16,
  height: 45,
  backgroundColor: '$slate1',
  border: '1px solid $slate3',
  borderRadius: 6,
  ...Gilroy.style,

  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'center',

  fontSize: 17,
  lineHeight: 1,
  color: '$slate11',

  '&::placeholder': {
    color: '$slate9',
  },

  '&:focus': { backgroundColor: '$slate2', borderColor: '$slate4' },
})

export const Label = styled('label', {
  display: 'none',
})
