'use client'

import { useState } from 'react'
import { useRouter } from 'next/navigation'
import { Button, Fieldset, Form, Input, Label } from '@/components/Home'

export default function ReceiptPicker() {
  const router = useRouter()

  const [userId, setUserId] = useState('')
  const [taxYear, setTaxYear] = useState('')

  const [isSubmitting, setSubmitting] = useState(false)

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    setSubmitting(true)

    if (taxYear) return router.push(`/users/${userId}/tax-year/${taxYear}`)
    return router.push(`/users/${userId}`)
  }

  const handleChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    const value = event.currentTarget.value
    if (event.target.name === 'userId') setUserId(value)
    if (event.target.name === 'taxYear') setTaxYear(value)
  }

  return (
    <Form onSubmit={handleSubmit}>
      <Fieldset>
        <Label htmlFor="userId">Enter User ID:</Label>
        <Input
          name="userId"
          type="text"
          value={userId}
          placeholder="Enter User ID"
          onChange={handleChange}
          typeof="number"
        />
      </Fieldset>
      <Fieldset>
        <Label htmlFor="taxYear">Choose a Tax Year:</Label>
        <Input
          disabled={!userId}
          list="tax-years"
          id="taxYear"
          name="taxYear"
          value={taxYear}
          placeholder="Choose a Tax Year"
          onChange={handleChange}
        />

        <datalist id="tax-years">
          <option value="2022" />
          <option value="2021" />
          <option value="2020" />
          <option value="2019" />
          <option value="2018" />
        </datalist>
      </Fieldset>
      <Fieldset css={{ justifySelf: 'flex-end' }}>
        <Button disabled={!userId || isSubmitting} type="submit">
          Get Receipts
        </Button>
      </Fieldset>
    </Form>
  )
}
