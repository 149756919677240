'use client'

import { Gilroy } from '@/styles/fonts'
import { styled } from '@/styles/stitches.config'

export const Heading = styled('div', {
  ...Gilroy.style,
  fontWeight: '700',
  color: '$slate12',

  variants: {
    h1: {
      true: {
        fontSize: '$7',
      },
    },
    h2: {
      true: {
        fontSize: '$6',
      },
    },
    h3: {
      true: {
        fontSize: '$5',
      },
    },
    h4: {
      true: {
        fontSize: '$4',
      },
    },
    h5: {
      true: {
        fontSize: '$3',
      },
    },
    h6: {
      true: {
        fontSize: '$2',
      },
    },
  },
})
