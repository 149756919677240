import localFont from 'next/font/local'

const sourcesanspro = localFont({
  src: [
    {
      path: './SourceSansPro-Regular.ttf',
      weight: '400',
      style: 'normal',
    },
    {
      path: './SourceSansPro-SemiBold.ttf',
      weight: '500',
      style: 'normal',
    },
    {
      path: './SourceSansPro-Bold.ttf',
      weight: '700',
      style: 'normal',
    },
  ],
  variable: '--font-sourcesanspro',
})

export default sourcesanspro
